import GoodsAPI from "@/plugins/axios/apis/goods";
import OssAPI from "@/plugins/axios/apis/oss";
import AuthAPI from "@/plugins/axios/apis/auth";
import PointsAPI from "@/plugins/axios/apis/points";
import UserAPI from "@/plugins/axios/apis/user";
import OrderAPI from "@/plugins/axios/apis/order";
import BillAPI from "@/plugins/axios/apis/bill";
import QuestAPI from "@/plugins/axios/apis/quest";
import BusinessAPI from '@/plugins/axios/apis/business'

class Merged {
    goods = GoodsAPI
    oss = OssAPI
    auth = AuthAPI
    points = PointsAPI
    user = UserAPI
    order = OrderAPI
    bill = BillAPI
    quest = QuestAPI
    buz = BusinessAPI
}

export default new Merged();
