import { AxiosResponse } from "axios";
import http from "@/plugins/axios/http";
import { PageParams } from "@/utils/models/page";

class BillAPI {
  GetBillCycles(p: PageParams): Promise<AxiosResponse<any>> {
    return http.get("/bill/cycle/get", {
      params: {
        "field": p.field,
        "search": p.search,
        "ord": p.ord,
        "amount": p.amo,
        "prv": p.prv,
      }
    });
  }

  PayBill(uuid: string, no: string, cmt: string): Promise<AxiosResponse<any>> {
    return http.post("/bill/cycle/pay", null, {
      params:{
        "uuid": uuid,
        "refNo": no,
        "comment": cmt
      }
    })
  }
}

export default new BillAPI();
