import { Module } from "vuex";

export default {
  namespaced: true,
  state: {
    token: "",
    name: "",
    uid: "",
    roles: "",
    expiration: "",
    remPass: false,
    userName: "",
    passPhrase: "",
    department: [],
  },
  mutations: {
    login(
      state,
      info: {
        uid: string;
        token: string;
        roles: string;
        exp: string;
        name: string;
        department: Array<never>,
      }
    ) {
      state.uid = info.uid;
      state.name = info.name;
      state.token = info.token;
      state.roles = info.roles;
      state.expiration = info.exp;
      state.department = info.department;
    },
    logout(state) {
      state.uid = "";
      state.name = "";
      state.token = "";
      state.roles = "";
      state.expiration = "";
    },
    remPass(
      state,
      info: {
        user: string;
        phrase: string;
      }
    ) {
      state.remPass = true;
      state.userName = info.user;
      state.passPhrase = info.phrase;
    },
    resetPass(state) {
      state.remPass = false;
      state.userName = "";
      state.passPhrase = "";
    },
  },
  actions: {},
  modules: {},
} as Module<any, any>;
