import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";
import moment from "moment";
import { ElMessage } from "element-plus";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/Login",
    component: () => import("@/views/Authentication/Login/Login.vue"),
  },
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/Company",
    meta: {
      requireLogin: true,
      requiredTypes: "company,staff,all",
    },
    component: () =>
      import(
        "@/components/templates/CompanyBackendTemplate/CompanyBackendTemplate.vue"
      ),
    children: [
      {
        path: "/Company/AddGoods",
        component: () =>
          import("@/views/GoodsSection/AddGoodsPage/AddGoodsPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/ModifyGoods/:state/:goods",
        component: () =>
          import("@/views/GoodsSection/ModifyGoodsPage/ModifyGoodsPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/GoodsList",
        component: () =>
          import("@/views/GoodsSection/GoodsListPage/GoodsListPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/LimitGoodsList",
        component: () =>
          import(
            "@/views/GoodsSection/LimitGoodsListPage/LimitGoodsListPage.vue"
          ),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/OrderList",
        component: () =>
          import("@/views/OrderSection/OrderListPage/OrderListPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/PlatformOrderList",
        component: () =>
          import(
            "@/views/OrderSection/PlatformOrderListPage/PlatformOrderListPage.vue"
          ),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/PointsLog",
        component: () =>
          import("@/views/PointsSection/PointsLogPage/PointsLogPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/ClientList",
        component: () =>
          import("@/views/UserSection/ClientListPage/ClientListPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/EmployeeList",
        component: () =>
          import("@/views/UserSection/EmployeeListPage/EmployeeListPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/BillCycle",
        component: () =>
          import("@/views/OrderSection/BillCyclePage/BillCyclePage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Company/Payment",
        component: () =>
          import("@/views/OrderSection/BillPaymentPage/BillPaymentPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Business/QuestList",
        component: () =>
          import("@/views/StatisticsSection/QuestListPage/QuestListPage.vue"),
        meta: {
          requireLogin: true,
          requiredTypes: "company",
        },
      },
      {
        path: "/Business/BusinessInfo",
        component: () =>
          import(
            "@/views/StatisticsSection/BusinessInfoPage/BusinessInfoPage.vue"
          ),
        meta: {
          requireLogin: true,
          requiredTypes: "company,staff,all",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { uid, token, roles, expiration } = store.state.local;
  const { requiredTypes } = to.meta;
  if (to.matched.some((record) => record.meta.requireLogin)) {
    if (uid === "" || token === "" || token.length < 32) {
      next({
        path: "/Login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else if ((requiredTypes as string).indexOf(roles as string) < 0) {
      next({
        path: "/Login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else if (moment(expiration).isBefore(moment())) {
      ElMessage({
        showClose: true,
        message: "登录已过期，请重新登陆！",
        type: "warning",
      });
      next({
        path: "/Login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
