import { PageParams } from "./../../../utils/models/page";
import { AxiosResponse } from "axios";
import http from "@/plugins/axios/http";
import { SendPointsForm, StaffInsert, ClientInsert } from "@/utils/models/user";

class UserAPI {
  snedUserPointsSingle(val: SendPointsForm): Promise<AxiosResponse<any>> {
    return http.post("/company/points/send-user", null, {
      params: {
        data: JSON.stringify(val),
      },
    });
  }

  snedUserPointsMulti(val: SendPointsForm[]): Promise<AxiosResponse<any>> {
    return http.post("/company/points/send-user/multi", null, {
      params: {
        data: JSON.stringify(val),
      },
    });
  }

  addClientSingle(user: ClientInsert): Promise<AxiosResponse<any>> {
    return http.post("/user/client/add", null, {
      params: {
        data: JSON.stringify(user),
      },
    });
  }

  addClientMulti(users: ClientInsert[]): Promise<AxiosResponse<any>> {
    return http.post("/user/client/add/multi", null, {
      params: {
        data: JSON.stringify(users),
      },
    });
  }

  addStaffSingle(user: StaffInsert): Promise<AxiosResponse<any>> {
    return http.post("/user/staff/add", null, {
      params: {
        data: JSON.stringify(user),
      },
    });
  }

  addStaffMulti(users: StaffInsert[]): Promise<AxiosResponse<any>> {
    return http.post("/user/staff/add/multi", null, {
      params: {
        data: JSON.stringify(users),
      },
    });
  }

  getClients(p: PageParams): Promise<AxiosResponse<any>> {
    return http.get("/user/client/get-by-cond", {
      params: {
        // "supplier": supplier,
        field: p.field,
        search: p.search,
        ord: p.ord,
        amount: p.amo,
        prv: p.prv,
      },
    });
  }

  getStaffs(p: PageParams): Promise<AxiosResponse<any>> {
    return http.get("/user/staff/get-by-cond", {
      params: {
        // "supplier": supplier,
        field: p.field,
        search: p.search,
        ord: p.ord,
        amount: p.amo,
        prv: p.prv,
      },
    });
  }

  searchStaffs(
    ord: string,
    prv: string,
    amount: string,
    query: string
  ): Promise<AxiosResponse<any>> {
    return http.get("/user/staff/search", {
      params: {
        // "supplier": supplier,
        prv: prv,
        ord: ord,
        amount: amount,
        query: query,
      },
    });
  }

  staffByDepartment(
    ord: string,
    prv: string,
    dep: string,
    amount: string
  ): Promise<AxiosResponse<any>> {
    return http.get("/user/staff/by-department", {
      params: {
        // "supplier": supplier,
        department: dep,
        prv: prv,
        ord: ord,
        amount: amount,
      },
    });
  }

  searchClients(
    ord: string,
    prv: string,
    amount: string,
    query: string
  ): Promise<AxiosResponse<any>> {
    return http.get("/client/search", {
      params: {
        // "supplier": supplier,
        prv: prv,
        ord: ord,
        amount: amount,
        query: query,
      },
    });
  }
}

export default new UserAPI();
