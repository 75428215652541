import { AxiosResponse } from "axios";
import http from "@/plugins/axios/http";
import { sha256 } from "js-sha256";

class AuthenticationAPI {
  companyLogin(
    user: string,
    pw: string,
  ): Promise<AxiosResponse<any>> {
    return http.post("/company/company-login", null, {
      params: {
        user: user,
        password: sha256(pw),
      },
    });
  }

  initPassPhrase(user: string, pw: string,): Promise<AxiosResponse<any>> {
    return http.post("/user/staff/init-password", null, {
      params: {
        user: user,
        pass: sha256(pw),
      },
    });
  }
}

export default new AuthenticationAPI();
