import { Module } from "vuex";
import { AxiosResponse } from "axios";
import MergedAPI from "@/plugins/axios/api";
import moment from "moment";
import { Page, IndexItems } from "@/utils/models/page";
import { Goods } from "@/utils/models/goods";
import { GoodsOrder } from "@/utils/models/order";

export const PAGE_SIZE = 50;

function page2idx(page: number, ratio: number) {
  return Math.floor((page - 1) / ratio);
}

function shiftPages(pgs: Page<IndexItems>[], pdx: number) {
  for (let i = pdx; i < pgs.length; i++) {
    if (pgs[i].data.length < PAGE_SIZE && i != pgs.length - 1) {
      const obj = pgs[i + 1].data.splice(0, 1)[0];
      pgs[i + 1].start = pgs[i + 1].data[0].id;
      pgs[i].data.push(obj);
      pgs[i].end = obj.id;
    } else if (pgs[i].data.length > PAGE_SIZE) {
      if (i != pgs.length - 1) {
        const obj = pgs[i].data.splice(pgs[i].data.length - 1, 1)[0];
        pgs[i].end = pgs[i].data[PAGE_SIZE - 1].id;
        pgs[i + 1].data.unshift(obj);
        pgs[i + 1].start = obj.id;
      } else {
        const obj = pgs[i].data.splice(pgs[i].data.length - 1, 1)[0];
        pgs[i].end = pgs[i].data[PAGE_SIZE - 1].id;
        pgs.push({
          start: obj.id,
          end: obj.id,
          data: [obj],
        });
      }
    }
  }
  return pgs;
}

function genPage(goods: IndexItems[]) {
  return {
    start: goods[0].id,
    end: goods[goods.length - 1].id,
    data: goods,
  } as Page<IndexItems>;
}

export default {
  namespaced: true,
  mutations: {},
  actions: {},
  modules: {
    sideNav: {
      namespaced: true,
      state: {
        isCollapsed: false,
        opends: ["1", "2", "3", "4"],
      },
      mutations: {
        updateCollapse(state, collapse) {
          state.isCollapsed = collapse;
        },
        updateOpends(state, opends) {
          state.opends = opends;
        },
      },
    },
    imgAccess: {
      namespaced: true,
      state: {
        updAccess: {} as AxiosResponse,
      },
      mutations: {
        refreshUpdAccess(state, resp) {
          state.updAccess = resp;
        },
      },
      actions: {
        async refreshUpdAccess(context) {
          const $ = MergedAPI;
          await $.oss.getCacheAccess().then((res) => {
            if (res.data && res.data.Expiration) {
              context.commit("refreshUpdAccess", res.data);
            }
          });
        },
      },
    },
    goodsList: {
      namespaced: true,
      state: {
        pages: [] as Page<Goods>[],
        idxSize: 10,
        ord: "desc",
        search: "",
        cIdx: 1,
        lIdx: 0,
        tab: "t",
        last_upd: [] as string[],
        stats: {
          t: 0, // online
          f: 0, // offline
          d: 0, // deleted
        },
        // for multi-state list (deprecated)
        // t: [], // online
        // f: [], // offline
        // d: [], // deleted
        // idx: {
        //     t: 0, // online
        //     f: 0, // offline
        //     d: 0, // deleted
        // },
        // upd_time: {
        //     t: [], // online
        //     f: [], // offline
        //     d: [], // deleted
        // }
      },
      mutations: {
        clearPages(state) {
          state.pages = [] as Page<Goods>[];
          // state.cIdx = 1;
          state.lIdx = 0;
          state.ord = "desc";
          state.idxSize = 10;
          state.last_upd = [];
        },
        insertPage(state, data: { list: Goods[] }) {
          if (data.list.length > 0) {
            state.pages.push(genPage(data.list));
            state.lIdx += Math.ceil(data.list.length / state.idxSize);
            state.last_upd.push(moment());
          }
        },
        changeSearch(state, search) {
          state.search = search;
        },
        changeIdx(state, idx) {
          state.cIdx = idx;
        },
        updatePage(state, data: { list: Goods[]; pg: number }) {
          const op = state.pages[data.pg];
          const np = genPage(data.list);
          state.pages[data.pg] = np;
          const dif = np.data.length - op.data.length;
          state.lIdx += Math.floor(dif / state.idxSize);
          if (dif < 0) {
            state.lIdx -= 1;
          }
          state.last_upd[data.pg] = moment();
        },
        updateOrd(state, ord) {
          state.ord = ord;
        },
        updateStats(state, stats) {
          for (const item of stats) {
            state.stats[item.state] = parseInt(item.goods_count, 10);
          }
        },
        switchTab(state, data: { to: string }) {
          state.tab = data.to;
        },
        // for multi-state list (deprecated)
        // updateSingleGoods(state, data: { obj: any, state: string }) {
        //     const idx = (state[data.state] as any[]).indexOf(data.obj);
        //     if (idx > -1) {
        //         (state[data.state] as any[])[idx] = data.obj;
        //     }
        // },
        // insertList(state, data: { val: Goods[], state: string, ord: string }) {
        //     state[data.state].push(genPage(data.val, data.ord));
        //     (state.upd_time[data.state]).push(moment());
        // },
        // updateList(state, data: { val: any, state: string, idx: number }) {
        //     state[data.state][data.idx] = data.val;
        //     state.upd_time[data.state][data.idx] = moment();
        // },
        // changeState(state, data: { obj: Goods, old: string, new: string, idx: number }) {
        //     const idx = (state[data.old][data.idx - 1].data as Goods[]).indexOf(data.obj);
        //     if (idx > -1) {
        //         //delete old
        //         (state[data.old][data.idx - 1].data as Goods[]).splice(idx, 1);
        //         state.stats[data.old] -= 1;
        //         if (state[data.old].length > data.idx) {
        //             const rep = (state[data.old][data.idx].data as Goods[]).splice(0, 1);
        //             (state[data.old][data.idx - 1].data as any[]).push(rep);
        //             if ((state[data.old][data.idx].data as any[]).length === 0) {
        //                 state[data.old].splice(data.idx, 1);
        //             }
        //         }
        //
        //         //add to new
        //         const pgs = state[data.new] as Page<Goods>[];
        //         let arrange = false;
        //         for (const p of pgs) {
        //             const ary = p.data as Goods[];
        //             if (p.ord === "asc" && data.obj.id < p.end && data.obj.id > p.start) {
        //                 for (const [i, g] of ary.entries()) {
        //                     if (data.obj.id < g.id && data.obj.id > ary[i - 1].id) {
        //                         p.data.splice(i, 0, data.obj);
        //                         if (p.data.length > PAGE_SIZE) {
        //                             arrange = true;
        //                         }
        //                         break;
        //                     }
        //                 }
        //                 break;
        //             }
        //             if (p.ord === "desc" && data.obj.id > p.end && data.obj.id < p.start) {
        //                 for (const [i, g] of ary.entries()) {
        //                     if (data.obj.id > g.id && data.obj.id < ary[i - 1].id) {
        //                         p.data.splice(i, 0, data.obj);
        //                         if (p.data.length > PAGE_SIZE) {
        //                             arrange = true;
        //                         }
        //                         break;
        //                     }
        //                 }
        //                 break;
        //             }
        //         }
        //         if (arrange) {
        //             (state[data.new] as any[]).push(data.obj);
        //         }
        //         state.stats[data.new] += 1;
        //     }
        // },
        // setOffline(state, obj) {
        //     (state.f as any[]).push(obj);
        //     (state.t as any[]).splice((state.t as any[]).indexOf(obj), 1);
        // },
        // setOnline(state, obj) {
        //     (state.t as any[]).push(obj);
        //     (state.f as any[]).splice((state.f as any[]).indexOf(obj), 1);
        // },
        // delPerm(state, obj) {
        //     (state.d as any[]).splice((state.d as any[]).indexOf(obj), 1);
        // },
      },
    },
    pageList: {
      namespaced: true,
      state: {
        pages: [] as Page<IndexItems>[],
        pageSize: 10,
        ord: "id desc",
        field: "",
        search: "",
        cIdx: 1,
        lIdx: 0,
        count: 0,
        pcount: 0,
        last_upd: [] as string[],
      },
      mutations: {
        clearPages(state) {
          state.pages = [] as Page<IndexItems>[];
          state.lIdx = 0;
          state.cIdx = 1;
          state.ord = "id desc";
          state.field = "";
          state.search = "";
          state.pageSize = 10;
          state.count = 0;
          state.pcount = 0;
          state.last_upd = [];
        },
        insertPage(state, data: IndexItems[]) {
          if (data.length > 0) {
            // const el = state.pages[state.pages.length - 1].length;
            // if (el === state.pageSize) {
            //     state.lIdx += Math.ceil(data.length / state.pageSize);
            // } else {
            //     state.lIdx += Math.ceil(data.length + state.pages[state.pages.length - 1].length / state.pageSize);
            // }
            const n = data.length;
            let i = 0;
            while (i < n) {
              state.pages.push(genPage(data.slice(i, (i += state.pageSize))));
              state.lIdx += 1;
              state.last_upd.push(moment());
            }
          }
        },
        updatePage(state, data: { list: IndexItems[]; pg: number }) {
          if (data.list.length > 0) {
            const n = data.list.length;
            let i = 0;
            while (i < n) {
              if (data.pg <= state.lIdx) {
                state.pages[data.pg - 1] = genPage(
                  data.list.slice(i, (i += state.pageSize))
                );
              } else {
                state.pages.push(
                  genPage(data.list.slice(i, (i += state.pageSize)))
                );
                state.lIdx += 1;
              }
              state.last_upd[data.pg - 1] = moment();
              data.pg++;
            }
          }
        },
        changeSearch(state, data: { field: string; search: string }) {
          state.field = data.field;
          state.search = data.search;
        },
        changeIdx(state, idx) {
          state.cIdx = idx;
        },
        changeOrd(state, ord) {
          state.ord = ord;
        },
        changeCount(state, c) {
          state.count = c;
          state.pcount = Math.ceil(c / state.pageSize);
        },
      },
    },

    orderList: {
      namespaced: true,
      state: {
        pages: [] as Page<GoodsOrder>[],
        idxSize: 10,
        ord: "desc",
        search: "",
        cIdx: 1,
        lIdx: 0,
        tab: "wfs",
        last_upd: [] as string[],
        stats: {
          wfs: 0, // online
          all: 0, // offline
        },
      },
      mutations: {
        clearPages(state) {
          state.pages = [] as Page<GoodsOrder>[];
          // state.cIdx = 1;
          state.lIdx = 0;
          state.ord = "desc";
          state.idxSize = 10;
          state.last_upd = [];
        },
        insertPage(state, data: { list: GoodsOrder[] }) {
          if (data.list.length > 0) {
            state.pages.push(genPage(data.list));
            state.lIdx += Math.ceil(data.list.length / state.idxSize);
            state.last_upd.push(moment());
          }
        },
        changeSearch(state, search) {
          state.search = search;
        },
        changeIdx(state, idx) {
          state.cIdx = idx;
        },
        updatePage(state, data: { list: GoodsOrder[]; pg: number }) {
          const op = state.pages[data.pg];
          const np = genPage(data.list);
          state.pages[data.pg] = np;
          const dif = np.data.length - op.data.length;
          state.lIdx += Math.floor(dif / state.idxSize);
          if (dif < 0) {
            state.lIdx -= 1;
          }
          state.last_upd[data.pg] = moment();
        },
        updateOrd(state, ord) {
          state.ord = ord;
        },
        updateStats(state, stats) {
          for (const item of stats) {
            state.stats[item.state] = parseInt(item.goods_count, 10);
          }
        },
        switchTab(state, data: { to: string }) {
          state.tab = data.to;
        },
        changeState(state, data: { obj: GoodsOrder; pdx: number }) {
          const pg = state.pages[data.pdx].data as GoodsOrder[];
          for (const [idx, g] of pg.entries()) {
            if (g.uuid === data.obj.uuid) {
              break;
            }
          }
        },
      },
    },
    pages: {
      namespaced: true,
      state: {
        pages: [],
        idxSize: 10,
        ord: "desc",
        search: "",
        cIdx: 1,
        lIdx: 0,
        count: 0,
        last_upd: [] as string[],
      },
      mutations: {
        clearPages(state) {
          state.pages = [];
          state.lIdx = 0;
          state.ord = "desc";
          state.idxSize = 10;
          state.last_upd = [];
          state.count = 0;
        },
        insertPage(state, data: { list: IndexItems[] }) {
          if (data.list.length > 0) {
            state.pages.push(genPage(data.list));
            state.lIdx += Math.ceil(data.list.length / state.idxSize);
            state.last_upd.push(moment());
          }
        },
        changeSearch(state, search) {
          state.search = search;
        },
        changeIdx(state, idx) {
          state.cIdx = idx;
        },
        updatePage(state, data: { list: IndexItems[]; pg: number }) {
          const op = state.pages[data.pg];
          const np = genPage(data.list);
          state.pages[data.pg] = np;
          const dif = np.data.length - op.data.length;
          state.lIdx += Math.floor(dif / state.idxSize);
          if (dif < 0) {
            state.lIdx -= 1;
          }
          state.last_upd[data.pg] = moment();
        },
        updateOrd(state, ord) {
          state.ord = ord;
        },
        updateCount(state, cnt) {
          state.count = cnt;
        },
      },
    },
  },
} as Module<any, any>;
