import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Element from '@/plugins/ElementPlus/index';
import svgPlugin from '@/plugins/svg/svg';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 中文化
import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale'
locale.use(lang);

const app = createApp(App).use(store).use(router);
//导入element组件
app.use(Element, { zhCn });
//导入svg
app.use(svgPlugin, {});

app.mount('#app');
