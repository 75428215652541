import axios, {AxiosInstance} from "axios";

const http: AxiosInstance = axios.create({
    // 服务器地址
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PROD_API : process.env.VUE_APP_DEV_API,
    // headers
    headers: {
        // "Content-type": "application/json",
        // "Content-Type": "multipart/form-data",
        "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    timeout: 30000
});

export default http;
