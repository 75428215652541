import { PageParams } from "@/utils/models/page";
import http from "@/plugins/axios/http";
import { AxiosResponse } from "axios";
import { GoodsOrder } from "@/utils/models/order";

class OrderAPI {
  getOrders(
    supllier: string,
    state: string,
    p: PageParams
  ): Promise<AxiosResponse<any>> {
    return http.get("/order/goods-order/get/by-supplier", {
      params: {
        supplier: supllier,
        state: state,
        field: p.field,
        search: p.search,
        ord: p.ord,
        amount: p.amo,
        prv: p.prv,
      },
    });
  }

  getPlatformOrders(p: PageParams): Promise<AxiosResponse<any>> {
    return http.get("/order/goods-order/get/ptf", {
      params: {
        field: p.field,
        search: p.search,
        ord: p.ord,
        amount: p.amo,
        prv: p.prv,
      },
    });
  }

  sendGoodsOrder(order: GoodsOrder): Promise<AxiosResponse<any>> {
    return http.post("/order/goods-order/send", null, {
      params: {
        order: order.uuid,
        log_no: order.log_no,
      },
    });
  }

  cancelGoodsOrder(order: GoodsOrder): Promise<AxiosResponse<any>> {
    return http.post("/order/goods-order/cancel", null, {
      params: {
        uuid: order.uuid,
      },
    });
  }

  sendClientOrder(uuid: string): Promise<AxiosResponse<any>> {
    return http.post("/order/client-order/send", null, {
      params: {
        order: uuid,
      },
    });
  }
}

export default new OrderAPI();
