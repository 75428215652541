import { BusinessLogForm } from './../../../utils/models/business';
import { AxiosResponse } from "axios";
import http from "@/plugins/axios/http";
import { PageParams } from "@/utils/models/page";
import { BusinessInfoCond } from "@/utils/models/business";

class BusinessAPI {
  GetBuzInfo(p: PageParams, cond: BusinessInfoCond): Promise<AxiosResponse<any>> {
    return http.post("/bzi/info/search", JSON.stringify(cond), {
      params: {
        "cond": cond,
        "ord": p.ord,
        "amount": p.amo,
        "prv": p.prv,
      }
    });
  }

  AddBuzLog(log: BusinessLogForm): Promise<AxiosResponse<any>> {
    return http.post("/bzi/log/add", JSON.stringify(log), {});
  }
}

export default new BusinessAPI();
