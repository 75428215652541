import {createStore} from 'vuex';
import createVuexAlong from "vuex-along";

import local from './local';
import session from './session';

export default createStore<any>({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        local: local,
        session: session,
    },
    plugins: [
        createVuexAlong({
            // 设置保存的集合名字，避免同站点下的多项目数据冲突
            name: "qx2-local-vuex-storage",
            local: {
                list: ["local"],
                // isFilter: true,
            },
            session: {
                list: ["session"],
            },
        }),
    ],
})
