import { App } from "vue";
//components imports
import {
  ElButton,
  ElSelect,
  ElInput,
  ElTable,
  ElTableColumn,
  ElRow,
  ElCol,
  ElMenu,
  ElSubmenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElContainer,
  ElHeader,
  ElAside,
  ElMain,
  ElDescriptions,
  ElDescriptionsItem,
  ElScrollbar,
  ElImage,
  ElImageViewer,
  ElForm,
  ElFormItem,
  ElUpload,
  ElMessage,
  ElMessageBox,
  ElProgress,
  ElInputNumber,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElOption,
  ElPopover,
  ElLoading,
  ElTabs,
  ElTabPane,
  ElPagination,
  ElCheckbox,
  ElDialog,
  ElDivider,
  ElDrawer,
  ElDatePicker,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElCarousel,
  ElCarouselItem,
} from "element-plus";
//scss imports
import "element-plus/packages/theme-chalk/src/base.scss";
import "element-plus/packages/theme-chalk/src/button.scss";
import "element-plus/packages/theme-chalk/src/select.scss";
import "element-plus/packages/theme-chalk/src/option.scss";
import "element-plus/packages/theme-chalk/src/input.scss";
import "element-plus/packages/theme-chalk/src/input-number.scss";
import "element-plus/packages/theme-chalk/src/table.scss";
import "element-plus/packages/theme-chalk/src/table-column.scss";
import "element-plus/packages/theme-chalk/src/row.scss";
import "element-plus/packages/theme-chalk/src/col.scss";
import "element-plus/packages/theme-chalk/src/menu.scss";
import "element-plus/packages/theme-chalk/src/submenu.scss";
import "element-plus/packages/theme-chalk/src/menu-item.scss";
import "element-plus/packages/theme-chalk/src/menu-item-group.scss";
import "element-plus/packages/theme-chalk/src/container.scss";
import "element-plus/packages/theme-chalk/src/header.scss";
import "element-plus/packages/theme-chalk/src/aside.scss";
import "element-plus/packages/theme-chalk/src/descriptions.scss";
import "element-plus/packages/theme-chalk/src/descriptions-item.scss";
import "element-plus/packages/theme-chalk/src/scrollbar.scss";
import "element-plus/packages/theme-chalk/src/image.scss";
import "element-plus/packages/theme-chalk/src/image-viewer.scss";
import "element-plus/packages/theme-chalk/src/form.scss";
import "element-plus/packages/theme-chalk/src/form-item.scss";
import "element-plus/packages/theme-chalk/src/message.scss";
import "element-plus/packages/theme-chalk/src/message-box.scss";
import "element-plus/packages/theme-chalk/src/progress.scss";
import "element-plus/packages/theme-chalk/src/radio.scss";
import "element-plus/packages/theme-chalk/src/radio-button.scss";
import "element-plus/packages/theme-chalk/src/radio-group.scss";
import "element-plus/packages/theme-chalk/src/popover.scss";
import "element-plus/packages/theme-chalk/src/loading.scss";
import "element-plus/packages/theme-chalk/src/tabs.scss";
import "element-plus/packages/theme-chalk/src/tab-pane.scss";
import "element-plus/packages/theme-chalk/src/pagination.scss";
import "element-plus/packages/theme-chalk/src/checkbox.scss";
import "element-plus/packages/theme-chalk/src/dialog.scss";
import "element-plus/packages/theme-chalk/src/divider.scss";
import "element-plus/packages/theme-chalk/src/drawer.scss";
import "element-plus/packages/theme-chalk/src/date-picker.scss";
import "element-plus/packages/theme-chalk/src/dropdown.scss";
import "element-plus/packages/theme-chalk/src/dropdown-menu.scss";
import "element-plus/packages/theme-chalk/src/dropdown-item.scss";
import "element-plus/packages/theme-chalk/src/carousel.scss";
import "element-plus/packages/theme-chalk/src/carousel-item.scss";

// import 'element-plus/packages/theme-chalk/src/upload.scss';
import "./upload.scss";
// import 'element-plus/packages/theme-chalk/src/main.scss';
import "./main.scss";

const list = [
  ElButton,
  ElSelect,
  ElOption,
  ElInput,
  ElInputNumber,
  ElTable,
  ElTableColumn,
  ElRow,
  ElCol,
  ElMenu,
  ElSubmenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElContainer,
  ElHeader,
  ElAside,
  ElMain,
  ElDescriptions,
  ElDescriptionsItem,
  ElScrollbar,
  ElImage,
  ElImageViewer,
  ElForm,
  ElFormItem,
  ElUpload,
  ElMessage,
  ElMessageBox,
  ElProgress,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElPopover,
  ElLoading,
  ElTabs,
  ElTabPane,
  ElPagination,
  ElCheckbox,
  ElDialog,
  ElDivider,
  ElDrawer,
  ElDatePicker,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElCarousel,
  ElCarouselItem,
];

const element = {
  install: function(app: App): void {
    list.forEach((obj: any) => {
      app.component(obj.name, obj);
    });
  },
};
export default element;
