import http from "@/plugins/axios/http";
import {AxiosResponse} from "axios";
import {AmountLimit, Goods} from "@/utils/models/goods";

class GoodsAPI {
    addGoods(goods: Goods): Promise<AxiosResponse<any>> {
        return http.post("/goods/add", null, {
            params: {
                "goods": JSON.stringify(goods)
            }
        });
    }

    addLimitGoods(goods: Goods, limit: AmountLimit): Promise<AxiosResponse<any>> {
        return http.post("/goods/limit/add", null, {
            params: {
                "goods": JSON.stringify(goods),
                "limit": JSON.stringify(limit),
            }
        });
    }

    modifyGoods(goods: Goods, delImgs: string[]): Promise<AxiosResponse<any>> {
        return http.post("/goods/modify", null, {
            params: {
                "goods": JSON.stringify(goods),
                "delImgs": delImgs.join(",")
            }
        });
    }

    modifyLimitGoods(goods: Goods, delImgs: string[], limit: AmountLimit): Promise<AxiosResponse<any>> {
        return http.post("/goods/limit/modify", null, {
            params: {
                "goods": JSON.stringify(goods),
                "delImgs": delImgs.join(","),
                "limit": JSON.stringify(limit),
            }
        });
    }

    deleteGoods(uuid: string, delImgs: string[]): Promise<AxiosResponse<any>> {
        return http.post("/goods/delete", null, {
            params: {
                "uuid": uuid,
                "delImgs": delImgs.join(","),
            }
        });
    }

    setGoodsState(uuid: string, state: string): Promise<AxiosResponse<any>> {
        return http.post("/goods/modify/state", null, {
            params: {
                "uuid": uuid,
                "state": state
            }
        });
    }

    setLimitGoodsState(uuid: string, state: string): Promise<AxiosResponse<any>> {
        return http.post("/goods/limit/modify/state", null, {
            params: {
                "uuid": uuid,
                "state": state
            }
        });
    }

    getGoodsBySupplier(supplier: string, state: string, ord: string, prv: string, amount: string, limit: string): Promise<AxiosResponse<any>> {
        return http.get("/goods/get/by-supplier", {
            params: {
                // "supplier": supplier,
                "state": state,
                "prv": prv,
                "ord": ord,
                "amount": amount,
                "limit": limit,
            }
        });
    }

    searchGoodsBySupplier(supplier: string, state: string, ord: string, prv: string, amount: string, field: string, search: string, limit: string): Promise<AxiosResponse<any>> {
        if (search === "" || search.length < 2) {
            return this.getGoodsBySupplier(supplier, state, ord, prv, amount, limit)
        }
        console.log(limit);
        return http.get("/goods/get/by-supplier/search", {
            params: {
                // "supplier": supplier,
                "state": state,
                "prv": prv,
                "ord": ord,
                "amount": amount,
                "field": field,
                "search": search,
                "limit": limit,
            }
        });
    }

    getGoodsByUUID(uuid: string): Promise<AxiosResponse<any>> {
        return http.get("/goods/get/by-uuid", {
            params: {
                "uuid": uuid
            }
        });
    }

    getLimitGoodsByUUID(uuid: string): Promise<AxiosResponse<any>> {
        return http.get("/goods/limit/get/by-uuid", {
            params: {
                "uuid": uuid
            }
        });
    }
}

export default new GoodsAPI();
