import { AddQuestLogForm } from "./../../../utils/models/quest";
import { PageParams } from "@/utils/models/page";
import { AxiosPromise } from "axios";
import http from "@/plugins/axios/http";
import {
  AddQuestGoalForm,
  AddQuestInfoForm,
  AddQuestListForm,
} from "@/utils/models/quest";

class QuestAPI {
  getQuestList(p: PageParams): Promise<AxiosPromise<any>> {
    return http.get("/quest/quest/get", {
      params: {
        field: p.field,
        search: p.search,
        ord: p.ord,
        amount: p.amo,
        prv: p.prv,
      },
    });
  }

  addQuestList(form: AddQuestListForm): Promise<AxiosPromise<any>> {
    return http.post("/quest/quest/add", null, {
      params: {
        quest_name: form.quest_name,
        quest_info: form.quest_info,
        quest_type: form.quest_type,
        award_type: form.award_type,
        created_at: form.created_at,
        due_date: form.due_date,
      },
    });
  }

  addQuestGoal(form: AddQuestGoalForm): Promise<AxiosPromise<any>> {
    return http.post("/quest/quest-goal/add", null, {
      params: {
        quest: form.quest,
        quest_goal: form.quest_goal,
        quest_reward: form.quest_reward,
        extra_reward: form.extra_reward,
      },
    });
  }

  addQuestInfo(form: AddQuestInfoForm): Promise<AxiosPromise<any>> {
    return http.post("/quest/quest-info/add", null, {
      params: {
        quest: form.quest,
        name: form.name,
        mobile: form.mobile,
        src_mobile: form.src_mobile,
        src_name: form.src_name,
        company: form.company,
        city: form.city,
        state: form.state,
        quest_goal: form.quest_goal,
        quest_reward: form.quest_reward,
        extra_reward: form.extra_reward,
        created_at: form.created_at,
      },
    });
  }

  addMultiQuestInfo(frms: AddQuestInfoForm[]): Promise<AxiosPromise<never>> {
    return http.post("/quest/quest-info/add-multi", JSON.stringify(frms), {});
  }

  addMultiQuestLog(frms: AddQuestLogForm[]): Promise<AxiosPromise<never>> {
    return http.post("/quest/quest-log/add-multi", JSON.stringify(frms), {});
  }

  getQuestGoal(quest: string): Promise<AxiosPromise<any>> {
    return http.get("/quest/quest-goal/get-by-quest", {
      params: {
        quest: quest,
      },
    });
  }

  getQuestInfo(p: PageParams, qid: string): Promise<AxiosPromise<any>> {
    return http.get("/quest/quest-info/get", {
      params: {
        qid: qid,
        field: p.field,
        search: p.search,
        ord: p.ord,
        amount: p.amo,
        prv: p.prv,
      },
    });
  }
}

export default new QuestAPI();
