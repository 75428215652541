import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", {
    class: "svg-icon",
    style: {
      width: _ctx.props.size + 'px',
      height: _ctx.props.size + 'px',
      color: _ctx.props.color
    }
  }, [
    _createVNode("use", {
      "xlink:href": `#icon-${_ctx.props.name}`,
      fill: _ctx.props.color
    }, null, 8, ["xlink:href", "fill"])
  ], 4))
}