import { AxiosResponse } from "axios";
import http from "@/plugins/axios/http";

class PointsAPI {
    getPointsByCompany(ord: string, prv: string, amount: string): Promise<AxiosResponse<any>> {
        return http.get("/points-log/get/by-company", {
            params: {
                // "supplier": supplier,
                "prv": prv,
                "ord": ord,
                "amount": amount,
            }
        });
    }

    searchPointsByCompany(ord: string, prv: string, amount: string, field: string, search: string): Promise<AxiosResponse<any>> {
        if (search === "" || search.length < 2) {
            return this.getPointsByCompany(ord, prv, amount)
        }
        return http.get("/points-log/get/by-company/search", {
            params: {
                // "supplier": supplier,
                "prv": prv,
                "ord": ord,
                "amount": amount,
                "field": field,
                "search": search,
            }
        });
    }
}

export default new PointsAPI();
