import http from "@/plugins/axios/http";
import {AxiosResponse} from "axios";

class OssAPI {
    getAll(): Promise<AxiosResponse<any>> {
        return http.get("/api/books");
    }

    getCacheAccess(): Promise<AxiosResponse<any>> {
        return http.get("/company/file-cache-access/grant");
    }
}

export default new OssAPI();
